import Doc from '../assets/img/file/doc.jfif';
import Docx from '../assets/img/file/docx.png';
import Pdf from '../assets/img/file/pdf.png';
import Xlsx from '../assets/img/file/xlsx.png';
import Dxf from '../assets/img/file/dxf.jpg';
import Psd from '../assets/img/file/psd.png';
import Dwg from '../assets/img/file/dwg.png';
import Video from '../assets/img/file/video.png';
import Idk from '../assets/img/attachment_preview_not_supported.png';

export const getPermission = (process, role) => {
    let data = {};
    let rejection = false, approval = false;
    if (process.completed) {
        data['Completed'] = true;
    } else if (process.failed) {
        data['Failed'] = true;
    } else {
        if (!process.client) {
            data['Client'] = true;
            data['STEP 0'] = true;
            if (role === 'Client') {
                approval = true;
            }
        }

        if (!process.client1 && process.shipping2 && process.clientStatus === 0) {
            data['Client'] = true;
            data['STEP 19'] = true;
            if (role === 'Client') {
                rejection = true;
                approval = true;
            }
        }

        if (!process.foreignTrade && process.client) {
            data['Foreign Trade'] = true;
            data['STEP 1'] = true;
            if (role === 'Foreign Trade') {
                if (!process.productionPlaning) {
                    rejection = true;
                }
                approval = true;
            }
        }

        if (!process.foreignTrade1 && process.finance1 && process.quality1 && !process.shipping2) {
            data['Foreign Trade'] = true;
            data['STEP 17'] = true;
            if (role === 'Foreign Trade') {
                approval = true;
            }
        }

        if (!process.productionPlaning && process.foreignTrade) {
            data['Production Planning'] = true;
            data['STEP 2'] = true;
            if (role.toLowerCase() === 'production planning') {
                rejection = true;
                approval = true;
            }
        }

        if (!process.productionPlaning1 && process.technic) {
            data['Production Planning'] = true;
            data['STEP 4'] = true;
            if (role.toLowerCase() === 'production planning') {
                rejection = true;
                approval = true;
            }
        }

        if (!process.productionPlaning2 && process.warehouse1) {
            data['Production Planning'] = true;
            data['STEP 14'] = true;
            if (role.toLowerCase() === 'production planning') {
                rejection = true;
                approval = true;
            }
        }

        if (!process.technic && process.productionPlaning && !process.productionPlaning1) {
            data['Technic'] = true;
            data['STEP 3'] = true;
            if (role === 'Technic') {
                rejection = true;
                approval = true;
            }
        }
        if (!process.shipping && process.productionPlaning1 && !process.purchasing) {
            data['Shipping'] = true;
            data['STEP 5'] = true;
            if (role === 'Shipping') {
                approval = true;
            }
        }
        if (!process.shipping1 && process.finance && !process.quality) {
            data['Shipping'] = true;
            data['STEP 10'] = true;
            if (role === 'Shipping') {
                approval = true;
                rejection = true;
            }
        }
        if (!process.shipping2 && process.foreignTrade1) {
            data['Shipping'] = true;
            data['STEP 18'] = true;
            if (role === 'Shipping') {
                approval = true;
                rejection = true;
            }
        }
        // if (!process.shipping2 && process.finance1 && process.quality1 && !process.client1) {
        //     data['Shipping'] = true;
        //     data['STEP 18'] = true;
        //     if (role === 'Shipping') {
        //         approval = true;
        //         rejection = true;
        //     }
        // }

        if (!process.purchasing && !process.warehouse && process.shipping) {
            data['Purchasing'] = true;
            data['STEP 6'] = true;
            if (role === 'Purchasing') {
                approval = true;
            }
        }

        if (!process.purchasing1 && process.warehouse && !process.finance) {
            data['Purchasing'] = true;
            data['STEP 8'] = true;
            if (role === 'Purchasing') {
                approval = true;
            }
        }

        if (!process.finance && process.purchasing1 && !process.shipping1) {
            data['Finance'] = true;
            data['STEP 9'] = true;
            if (role === 'Finance') {
                rejection = true;
                approval = true;
            }
        }

        if (!process.finance1 && process.production && process.financeStatus === 0) {
            data['Finance'] = true;
            data['STEP 16'] = true;
            if (role === 'Finance') {
                rejection = true;
                approval = true;
            }
        }

        if (!process.warehouse && process.purchasing && !process.purchasing1) {
            data['Warehouse'] = true;
            data['STEP 7'] = true;
            if (role === 'Warehouse') {
                approval = true;
            }
        }

        if (!process.warehouse1 && process.quality && !process.productionPlaning2) {
            data['Warehouse'] = true;
            data['STEP 12'] = true;
            if (role === 'Warehouse') {
                approval = true;
            }
        }
        if (!process.quality && process.shipping1 && !process.warehouse1) {
            data['Quality'] = true;
            data['STEP 11'] = true;
            if (role === 'Quality') {
                rejection = true;
                approval = true;
            }
        }

        if (!process.quality1 && process.production) {
            data['Quality'] = true;
            data['STEP 16'] = true;
            if (role === 'Quality') {
                rejection = true;
                approval = true;
            }
        }

        if (!process.quality2 && process.clientStatus === -1) {
            data['Quality'] = true;
            data['STEP 20'] = true;
            if (role === 'Quality') {
                rejection = true;
                approval = true;
            }
        }

        if (!process.production && process.productionPlaning2) {
            data['Production'] = true;
            data['STEP 15'] = true;
            if (role === 'Production') {
                rejection = true;
                approval = true;
            }
        }
    }
    return { data, rejection, approval }
}

export const getImg = (file) => {
    let type = file.originalname.split('.').pop();
    switch (type) {
        case 'vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        case 'xls':
        case 'xlsx':
        case 'csv':
        case 'rtf':
            return Xlsx;
        case 'vnd.openxmlformats-officedocument.wordprocessingml.document':
        case 'docx':
            return Docx;
        case 'doc':
            return Doc;
        case 'pdf':
            return Pdf;
        case 'dxf':
            return Dxf;
        case 'psd':
            return Psd;
        case 'dwg':
            return Dwg;
        case 'mp4':
        case 'webm':
        case 'ogg':
        case 'avi':
        case 'mov':
            return Video;
        default:
            return Idk;
    }
}

export const getSize = (size) => {
    let count = 0, unit = '';
    while (count <= 4) {
        if (size > 0 && size < 1) {
            size *= 1024;
            count++;
        } else break;
    }
    switch (count) {
        case 0: {
            unit = "GB";
            break;
        }
        case 1: {
            unit = "MB";
            break;
        }
        case 2: {
            unit = "KB";
            break;
        }
        case 3: {
            unit = "Bytes";
            break;
        }
        default:
            unit = 'GB';
    }

    return Number(size).toFixed(2) + " " + unit;
}